<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-tabs
      v-model="mainTab"
      background-color="transparent"
      centered
      icons-and-text
      show-arrows
    >
      <v-tab
        v-for="(tab, i) in companyClassObj.mainTabs"
        :key="i"
      >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>

      <v-tabs-items
        v-model="mainTab"
        class="transparent"
      >
        <v-tab-item>
          <companies
            :title="companyClassObj.title"
            :icon="companyClassObj.icon"
            :company-class="companyClassObj.companyClass"
          />
        </v-tab-item>

        <v-tab-item>
          <add-company-class
            :title="companyClassObj.title"
            :add-url="companyClassObj.addUrl"
            :company-class-id="companyClassObj.companyClassId"
          />
        </v-tab-item>
      </v-tabs-items>
    </base-material-tabs>
  </v-container>
</template>

<script>
  export default {
    components: {
      Companies: () => import('./Companies'),
      AddCompanyClass: () => import('./AddCompanyClass'),
    },

    props: {
      companyClass: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      mainTab: 0,
      agency: {
        title: 'Agencies',
        addTitle: 'Add new agency',
        icon: 'mdi-umbrella',
        companyClass: 'agency',
        companyClassId: 4,
        mainTabs: [
          { text: 'Agencies', icon: 'mdi-umbrella' },
          { text: 'Add new agency', icon: 'mdi-plus' },
        ],
      },
      carrier: {
        title: 'Carriers',
        addTitle: 'Add new carrier',
        icon: 'mdi-bank',
        companyClass: 'carrier',
        companyClassId: 3,
        mainTabs: [
          { text: 'Carriers', icon: 'mdi-bank' },
          { text: 'Add new carrier', icon: 'mdi-plus' },
        ],
      },
      finance: {
        title: 'Finance',
        addTitle: 'Add new finance',
        icon: 'mdi-piggy-bank',
        companyClass: 'finance',
        companyClassId: 2,
        mainTabs: [
          { text: 'Finances', icon: 'mdi-piggy-bank' },
          { text: 'Add new finance', icon: 'mdi-plus' },
        ],
      },
    }),

    computed: {
      companyClassObj () {
        switch (this.companyClass) {
          case 'agency':
            return this.agency
          case 'carrier':
            return this.carrier
          case 'finance':
            return this.finance
          default:
            return this.agency
        }
      },
    },
  }
</script>
